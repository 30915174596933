import React from "react";
import Button from "./index";
import cn from "classnames";
import { TicketButtonProps } from "./types";
import { useI18n } from "@/plugins/i18n";
import { TICKETS_TZ } from "@/constants";

const TicketButton: React.FC<TicketButtonProps<any>> = React.memo(
  ({
    children,
    radarioId,
    datetimeISO,
    url,
    className,
    variant,
    disabled,
    ...rest
  }) => {
    const [mounted, setMounted] = React.useState(false);
    const hasPassed = new Date() >= new Date(`${datetimeISO}${TICKETS_TZ}`);
    const { t } = useI18n();

    React.useEffect(() => {
      setMounted(true);
    }, []);

    let href = undefined;

    if (!hasPassed) {
      href = url || (radarioId ? `#event/${radarioId}` : "#");
    }

    return hasPassed ? (
      <Button
        is={"div"}
        className={cn(
          { "opacity-100": mounted, "opacity-0": !mounted },
          className
        )}
        {...rest}
        variant={"strikethrough"}
      >
        {t("play_has_passed")}
      </Button>
    ) : (
      <Button
        is={"a"}
        href={href}
        className={cn(
          { "opacity-100": mounted, "opacity-0": !mounted },
          className
        )}
        disabled={disabled}
        {...rest}
        variant={variant}
      >
        {children}
      </Button>
    );
  },
  () => false
);

export default TicketButton;

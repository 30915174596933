import React from "react";
import isEqual from "react-fast-compare";
import Button from "@/components/kit/button";
import TicketButton from "@/components/kit/button/ticket-button";
import cn from "classnames";
import { useRoute } from "@/utils";
import { AtomicTicketByDay as AtomicTicketByDayT } from "./types";
import * as css from "./program.modules.scss";
import { useStateValue } from "@/store";
import { AniLink } from "@/components/global-navigation";

type TicketsFeedProps = {
  items: AtomicTicketByDayT[];
  buyTicketText: string;
  canBuy: boolean;
  className?: string;
};

const AtomicTicketByDay: React.FC<
  AtomicTicketByDayT & { canBuy: boolean }
> = React.memo(
  ({ day, atomicTickets, buyTicketText, canBuy }) => {
    const route = useRoute();
    const [{ locale }] = useStateValue();

    return (
      <div className={cn("relative", css.upByStdBorder)}>
        <div className="hidden tablet:block absolute top-0 left-0 h-full w-2/3 tablet:w-3/24">
          <div className="tablet:sticky top-0 pt-5 tablet:py-4 desktop:py-5 uppercase text-title-2 z-10">
            {day}
          </div>
        </div>

        <div className={cn("flex flex-col w-full")}>
          {atomicTickets.map(
            (
              {
                datetime,
                datetimeISO,
                author,
                title,
                venue,
                venueEn,
                address,
                addressEn,
                radarioId,
                url,
                slug,
              },
              i
            ) => (
              <div
                className={cn(
                  css.atomicTicketWrapper,
                  "flex flex-col tablet:flex-row",
                  {
                    "border-t-2 desktop1440:border-t-3": true,
                  }
                )}
                key={i}
              >
                <div
                  className={cn("tablet:w-4/24 desktop:w-3/24", {
                    [css.upByStdBorder]: i > 0,
                    "border-t-2 desktop1440:border-t-3 border-white": i > 0,
                  })}
                />
                <div className="flex tablet:block justify-between tablet:w-3/24 text-title-2 uppercase text-right pt-5 pb-3 tablet:pt-4 tablet:pb-7 desktop:pt-5 desktop1440:pb-9">
                  <span className={"tablet:hidden"}>{day}</span>
                  <span>{datetime}</span>
                </div>
                <div
                  className={cn(
                    "hidden tablet:block w-1/24 border-t-2 desktop1440:border-t-3 border-white ",
                    css.upByStdBorder
                  )}
                />
                <AniLink
                  to={route("home", slug)}
                  className="block tablet:w-7/24 desktop:w-10/24 text-h3 tablet:text-title-2 desktop1280:text-title-1 uppercase tablet:pt-4 tablet:pb-7 desktop:pt-5 desktop1440:pb-9"
                >
                  {author && (
                    <span className="font-bold">{author} &mdash; </span>
                  )}
                  {title}
                </AniLink>
                <div className="hidden tablet:block w-1/24" />
                <div className="tablet:w-8/24 desktop:w-6/24 pt-7 pb-6 tablet:pt-4 tablet:pb-7 desktop:pt-5 desktop1440:pb-9">
                  <div>{locale === "en" ? venueEn : venue}</div>
                  <div>{locale === "en" ? addressEn : address}</div>
                  {canBuy && (
                    <div className="mt-3 desktop:mt-5">
                      <TicketButton
                        radarioId={radarioId}
                        url={url}
                        variant="outlined"
                        className="w-full"
                        datetimeISO={datetimeISO}
                      >
                        {buyTicketText}
                      </TicketButton>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  },
  () => false
);

const TicketsFeed: React.FC<TicketsFeedProps> = ({
  items,
  buyTicketText,
  canBuy,
  className,
}) => {
  return (
    <div className={cn(className, "flex flex-col pt-6 tablet:pt-0 ")}>
      {items.map((atomicTicketByDay, i) => (
        <AtomicTicketByDay
          key={atomicTicketByDay.day}
          {...atomicTicketByDay}
          buyTicketText={buyTicketText}
          canBuy={canBuy}
        />
      ))}
    </div>
  );
};

export default TicketsFeed;

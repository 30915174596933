import React from "react";
import { graphql } from "gatsby";
import Program from "@/components/pages/program";
import SEOEn from "@/components/seo/index.en";
import SEORu from "@/components/seo/index.ru";

const ProgramEn = (props) => {
  const { pageContext } = props;

  return (
    <>
      {pageContext.langKey === "en" && (
        <SEOEn
          title={props.data.layoutProgram.title?.title}
          description={props.data.layoutProgram.ogDescription?.ogDescription}
          ogImage={props.data.layoutProgram.ogImage}
        />
      )}
      {pageContext.langKey === "ru" && (
        <SEORu
          title={props.data.layoutProgram.title?.title}
          description={props.data.layoutProgram.ogDescription?.ogDescription}
          ogImage={props.data.layoutProgram.ogImage}
        />
      )}
      <Program {...props} />
    </>
  );
};

export default ProgramEn;

export const pageQuery = graphql`
  query($locale: String!, $langKey: String!) {
    siteSettings: contentfulSiteSettings(node_locale: { eq: $locale }) {
      covidNotification {
        childMarkdownRemark {
          html
        }
      }
    }
    allAtomicTicketsByDay(node_locale: $locale) {
      day(formatString: "D MMM, ddd", locale: $langKey)
      atomicTickets {
        title
        slug
        venue
        venueEn
        address
        addressEn
        author
        soldOut
        datetime(formatString: "HH:mm")
        datetimeISO
        radarioId
        url
      }
    }
    layoutHome: contentfulLayoutHome {
      pageMode
    }
    layoutProgram: contentfulLayoutProgram(node_locale: { eq: $locale }) {
      ...ProgramPageFragment
      shows {
        cover_listing_image {
          fluid(maxWidth: 700) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
        author
        previewDescription {
          childMarkdownRemark {
            html
          }
        }
        title
        slug
        allTicketsUrl {
          allTicketsUrl
        }
        soldOut
        fewTicketsLeft
        atomicTickets(limit: 7, datetimeOrder: "asc") {
          cityShortCode
          cityShortCodeEn
          datetime(formatString: "D MMM,|ddd|HH:mm", locale: $langKey)
          datetimeISO
          radarioId
          url
          soldOut
        }
        ticketsJson {
          name
          tickets {
            address
            addressEn
          }
        }
      }
    }
  }
`;

import React from "react";
import isEqual from "react-fast-compare";
import Img from "gatsby-image";
import Button from "@/components/kit/button";
import { useRoute } from "@/utils";
import { ShowProps } from "@/components/pages/show/types";
import cn from "classnames";
import TicketBubbleGroup from "@/components/ticket-bubble/group";
import { AniLink } from "@/components/global-navigation";
import { useI18n } from "@/plugins/i18n";
import * as css from "./program.modules.scss";

type ProgramFeedProps = {
  items: ShowProps[];
  soldOutText: string;
  lowQtyText: string;
  canBuy: boolean;
  className?: string;
};

const ShowPreview: React.FC<
  ShowProps & Omit<ProgramFeedProps, "items"> & { canBuy: boolean }
> = React.memo(
  ({
    cover_listing_image,
    slug,
    title,
    author,
    previewDescription,
    soldOut,
    fewTicketsLeft,
    soldOutText,
    lowQtyText,
    ticketsJson,
    atomicTickets,
    canBuy,
  }) => {
    const route = useRoute();
    const ticketGroupsCount = ticketsJson?.tickets?.length;
    const { t } = useI18n();

    const ticketsBlock = (
      <>
        {soldOut && (
          <Button
            is={"button"}
            className={"w-full"}
            variant={soldOut ? "strikethrough" : "contained"}
            disabled={soldOut}
          >
            {soldOutText}
          </Button>
        )}
        {!soldOut && (
          <Button
            is={AniLink}
            to={route("home", slug)}
            className={"w-full"}
            variant={soldOut ? "strikethrough" : "contained"}
          >
            {canBuy ? t("tickets") : t("schedule")}
          </Button>
        )}

        {!soldOut && fewTicketsLeft && (
          <div className="uppercase flex items-center mt-2">
            <div className={cn(css.exclamationMarkIcon, "mr-3")}>!</div>
            {lowQtyText}
          </div>
        )}

        {!soldOut && Array.isArray(atomicTickets) && (
          <TicketBubbleGroup
            maxCount={6}
            tickets={atomicTickets}
            className={"mt-2 desktop1280:mt-3"}
            showCityShortCode={ticketGroupsCount > 1}
            classNameTicketBubble={"w-1/3 tablet:w-2/7 desktop:w-1/3"}
            slug={slug}
            canBuy={canBuy}
          />
        )}
      </>
    );

    return (
      <article className="flex flex-col tablet:flex-row">
        <aside className="tablet:w-1/2 desktop:w-1/4">
          {cover_listing_image ? (
            <AniLink
              to={route("home", slug)}
              className="block w-full hover:no-underline"
            >
              <Img {...cover_listing_image} />
            </AniLink>
          ) : (
            <div className={css.coverPlaceholder}></div>
          )}
        </aside>
        <div className={"hidden tablet:block w-1/24"}></div>
        <main className={"tablet:w-11/24 desktop:w-10/24"}>
          <AniLink to={route("home", slug)} className="block mt-6 tablet:mt-0">
            {author && (
              <span className="uppercase text-h3 font-bold">
                {author} &mdash;{" "}
              </span>
            )}
            <span className="uppercase text-h3">{title}</span>
          </AniLink>
          {previewDescription && (
            <AniLink
              to={route("home", slug)}
              className="block mt-3 tablet:mt-4 hover:no-underline"
              dangerouslySetInnerHTML={{
                __html: previewDescription.childMarkdownRemark.html,
              }}
            />
          )}

          <div className="block desktop:hidden mt-6 tablet:mt-5">
            {ticketsBlock}
          </div>
        </main>
        <div className={"hidden desktop:block w-1/24"}></div>
        <footer className="hidden desktop:block tablet:w-2/3 desktop:w-6/24">
          {ticketsBlock}
        </footer>
      </article>
    );
  },
  isEqual
);

const ProgramFeed: React.FC<ProgramFeedProps> = ({
  items,
  soldOutText,
  lowQtyText,
  canBuy,
  className,
}) => {
  return (
    <div
      className={cn(
        className,
        "space-y-10 tablet:space-y-15 desktop:space-y-7 desktop1280:space-y-9 desktop1440:space-y-10 pt-6 tablet:pt-9 desktop:pt-5 desktop1280:pt-9 desktop1440:pt-10"
      )}
    >
      {Array.isArray(items) &&
        items.map((show, i) => (
          <ShowPreview
            soldOutText={soldOutText}
            lowQtyText={lowQtyText}
            key={i}
            canBuy={canBuy}
            {...show}
          />
        ))}
    </div>
  );
};

export default ProgramFeed;

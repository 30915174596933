import React, { MouseEvent } from "react";
import { graphql } from "gatsby";
import { CSSTransition } from "react-transition-group";
import qs from "qs";
import PageTitle from "@/components/kit/page-title";
import cn from "classnames";
import * as styleConstants from "@/constants/style";
import Button from "@/components/kit/button";
import DownloadIcon from "@/assets/icons/download.inline.svg";
import { externalLinkProps, useRoute } from "@/utils";
import { useLocation, navigate } from "@reach/router";
import { HOME_PAGE_SEASON_MODE } from "@/constants";
import ProgramFeed from "./programFeed";
import TicketsFeed from "./ticketsFeed";
import * as css from "./program.modules.scss";
import { useCallback } from "react";
import Wysiwyg from "@/components/kit/wysiwyg";

const PROGRAM_TAB = "program";
const TICKETS_TAB = "tickets";
const DEFAULT_TAB = PROGRAM_TAB;
type TabValue = typeof PROGRAM_TAB | typeof TICKETS_TAB;

const Program = ({ data }) => {
  const {
    layoutProgram,
    layoutHome,
    allAtomicTicketsByDay,
    siteSettings,
  } = data;
  const route = useRoute();
  const location = useLocation();

  const covidNotificationHtml =
    siteSettings?.covidNotification?.childMarkdownRemark?.html;

  const query = qs.parse(location.search.replace(/^\?/, ""));
  const canBuyTickets = layoutHome.pageMode === HOME_PAGE_SEASON_MODE;

  const [activeTab, setTab] = React.useState<TabValue>(() => {
    return [PROGRAM_TAB, TICKETS_TAB].indexOf(query.tab) > -1
      ? query.tab
      : DEFAULT_TAB;
  });

  React.useEffect(() => {
    navigate(
      activeTab === DEFAULT_TAB
        ? route("program")
        : `${route("program")}?tab=${activeTab}`,
      { replace: true }
    );
  }, [activeTab]);

  const handleTabClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const tabId = e.currentTarget.dataset.tab as TabValue;
    setTab(tabId);
  }, []);

  return (
    <div className="mb-8 desktop:pt-10 desktop1280:pt-12 desktop1440:pt-14">
      <PageTitle border={false}>
        {layoutProgram.title && (
          <span
            dangerouslySetInnerHTML={{
              __html: layoutProgram.title.title.replace(/\n/g, "<br>"),
            }}
          />
        )}
      </PageTitle>

      <div className={cn(styleConstants.container)}>
        <div
          className={cn(
            "flex flex-col tablet:flex-row",
            "desktop1440:border-b-3 tablet:border-b-2",
            "tablet:pb-8 desktop:pb-11 desktop1280:pb-14 desktop1440:pb-15",
            "relative z-10"
          )}
        >
          <div className="tablet:w-7/24 desktop:w-1/4 pb-8 tablet:pb-0 border-b-2 tablet:border-0">
            {layoutProgram.programFile && (
              <Button
                is="a"
                href={layoutProgram.programFile.file.url}
                className="w-full"
                variant="outlined"
                renderIcon={() => <DownloadIcon className={css.downloadIcon} />}
                {...externalLinkProps}
              >
                {layoutProgram.programFileButtonText || "Скачать"}
              </Button>
            )}
          </div>
          <div className={"hidden tablet:block w-1/24"}></div>
          <div className="tablet:w-2/3 desktop:w-17/24 pt-4 tablet:pt-0 flex">
            <Button
              className="w-1/2"
              variant={activeTab === PROGRAM_TAB ? "contained" : "outlined"}
              noHover={activeTab === PROGRAM_TAB}
              data-tab={PROGRAM_TAB}
              onClick={handleTabClick}
            >
              {layoutProgram.programTabTitle}
            </Button>
            <Button
              className="w-1/2"
              variant={activeTab === TICKETS_TAB ? "contained" : "outlined"}
              noHover={activeTab === TICKETS_TAB}
              data-tab={TICKETS_TAB}
              onClick={handleTabClick}
            >
              {layoutProgram.ticketsTabTitle}
            </Button>
          </div>
        </div>
      </div>

      <div className={cn(styleConstants.container)}>
        {covidNotificationHtml && (
          <Wysiwyg
            className={cn("border p-4 mt-5 desktop:mt-4 desktop1280:mt-8")}
          >
            {covidNotificationHtml}
          </Wysiwyg>
        )}

        <div className={"relative"}>
          {Array.isArray(layoutProgram.shows) && (
            <CSSTransition
              in={activeTab === PROGRAM_TAB}
              timeout={220}
              classNames={"tabLeft"}
              unmountOnExit
            >
              <ProgramFeed
                className={cn(css.tab)}
                soldOutText={layoutProgram.soldOutText}
                lowQtyText={layoutProgram.lowQtyText}
                items={layoutProgram.shows}
                canBuy={canBuyTickets}
              />
            </CSSTransition>
          )}
          {Array.isArray(allAtomicTicketsByDay) && (
            <CSSTransition
              in={activeTab === TICKETS_TAB}
              timeout={220}
              classNames={"tabRight"}
              unmountOnExit
            >
              <TicketsFeed
                className={cn(
                  css.tab,
                  covidNotificationHtml &&
                    "pt-6 tablet:pt-9 desktop:pt-5 desktop1280:pt-9 desktop1440:pt-10"
                )}
                items={allAtomicTicketsByDay}
                buyTicketText={layoutProgram.buyTicketsText}
                canBuy={canBuyTickets}
              />
            </CSSTransition>
          )}
        </div>
      </div>
    </div>
  );
};

export default Program;

export const programPageFragment = graphql`
  fragment ProgramPageFragment on ContentfulLayoutProgram {
    title {
      title
    }
    buyTicketsText
    ticketsTabTitle
    soldOutText
    programTabTitle
    programFileButtonText
    programFile {
      file {
        url
      }
    }
    lowQtyText
    ogDescription {
      ogDescription
    }
    ogImage {
      file {
        url
        details {
          image {
            height
            width
          }
        }
      }
    }
  }
`;
